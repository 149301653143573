@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=ZCOOL+QingKe+HuangYou&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Slackey&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Uncial+Antiqua&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Frijole&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Walter+Turncoat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
#app,
#app > div {
  height: 100%;
}

/* Videogame */
/* Digital */
/* Bob sponja */
/* Irish */
/* Shake */

